<template>
  <div :tt="clientWidth">
    <template v-if="isMobile">
      <div style="width:90%; margin: 50px auto">
        <Login ref="loginBoxM" />
      </div>
    </template>
    <template v-else>
      <div class="loginBox" >
        
          <div class="left">
            <p class="logo"><img src="../../assets/images/logo.png" alt=""></p>
            <p class="img">
              <img src="../../assets/images/login_left_img.png" alt="">
            </p>
          </div>
          <div class="right">
            <div class="box">
              <Login ref="loginBox" />
            </div>
          </div>
      </div>
    </template>
  </div>
</template>

<script>
import Login from './components/Login.vue';
  export default {
  name: 'loginPage',
  components:{Login},
  data(){
    return {
      clientWidth:0
    }
  },
  computed: {
    isMobile() {
      return !(this.clientWidth > 900)
    }
  },
  created() {
  },
  methods: {
    
  },
  mounted() {
    this.clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth || 10;
    window.addEventListener("resize", () => {
      this.clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth || 10;
      console.log(this.clientWidth, 'this.clientWidth');
    });
    const _thisVue = this;
    const storageHandler = function () {
      _thisVue.$store.commit("syncToken", {
        LbjtAccessToken: localStorage.LbjtAccessToken || null,
        LbjtTokenType: localStorage.LbjtTokenType || null,
        LbjtExpiresTime: localStorage.LbjtExpiresTime || null,
      });
    };
    window.addEventListener("storage", storageHandler, false);
  },
  }
</script>

<style lang="scss" scoped>
</style>
